import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./Header.less";
import Location from "../Location/Location";
import RolesDropdown from "../RolesDropdown/RolesDropdown";
import permit, {
  checkAdditionalRoleAccessForUser,
  checkVendorEnabled,
} from "../../util/Permissions";
import getLogoutUrl from "../../util/getLogoutUrl";
import { useLocation, useNavigate } from "react-router-dom";
import { MailContext, MailMessageContext } from "../../context/RootContext";
import { Button, Drawer, Grid, Menu } from "antd";
import { ReactComponent as HomeDepotIcon } from "./Images/home-depot.svg";
import { ReactComponent as MailIcon } from "./Images/email.svg";
import { ReactComponent as EMTIcon } from "./Images/emtIcon.svg";
import { ReactComponent as MarsIcon } from "./Images/marsIcon.svg";
import { ReactComponent as DataMaintenanceIcon } from "./Images/DataMaintenance.svg";
import { ReactComponent as SthIcon } from "./Images/Ship-to-Home.svg";
import { ReactComponent as ApplianceIcon } from "./Images/Washer.svg";
import { ReactComponent as RoutePlannerViewIcon } from "./Images/Routeplanner_Icon.svg";
import { ReactComponent as LBDFDCIcon } from "./Images/Delivery-Express.svg";
import LocationContext from "../../context/LocationContext";
import { ReactComponent as WorkBinIcon } from "./Images/workBinIcon.svg";
import * as constants from "../../util/Constants";
import { DMP_WORKBIN_ACCESS_GROUP as workBin } from "../../util/Constants";
import useNewRelicMetrics from "../../hooks/newRelicMetricsHook";
import {
  HEADER_NAV_BUTTON_EVENT,
  HEADER_NAV_MENU,
} from "../../util/NewRelicConstants";

const Header = (props) => {
  const { useBreakpoint } = Grid;
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = props;
  const { md } = useBreakpoint();
  const [activeItem, setActiveItem] = useState(location.pathname);
  const [visible, setVisible] = useState(false);
  const { deleteMail } = useContext(MailContext);
  const { cleanMailMessage } = useContext(MailMessageContext);
  const { currentLocation } = useContext(LocationContext);
  const { logNewRelicMetricsEvent } = useNewRelicMetrics();

  useEffect(() => {
    if (location.pathname.startsWith("/route-planner/routes")) {
      setActiveItem("/route-planner/routes");
    } else {
      setActiveItem(location.pathname);
    }
  }, [location]);

  const logout = () => {
    logNewRelicMetricsEvent(HEADER_NAV_BUTTON_EVENT, {
      location: "/identity/logout",
      text: "Logout",
    });
    localStorage.clear();
    sessionStorage.clear();
    window.open(getLogoutUrl(), "_self");
  };

  const routeChange = (key) => {
    logNewRelicMetricsEvent(HEADER_NAV_BUTTON_EVENT, {
      location: key,
      text: HEADER_NAV_MENU[key],
    });
    setActiveItem(key);
    switch (key) {
      case "/":
      case "/workbin":
      case "/mail":
        if (key === "/mail") {
          deleteMail();
          cleanMailMessage();
        }
        navigate(key);
        break;
      case "/dca":
      case "/dashboard-sth":
      case "/dashboard-common":
        navigate(key);
        break;
      case "/route-planner/routes":
        navigate("/route-planner/routes/planning");
        break;
      case "mars":
        window.open(process.env.REACT_APP_MARS_URL, "_blank");
        break;
      case "emt":
        window.open(process.env.REACT_APP_EMT_URL, "_blank");
        break;
      default:
        break;
    }
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  function shouldDisplayDashboardCommon() {
    if (permit("dashboardCommonAccess", user)) {
      if (process.env.REACT_APP_DMP_DASHBOARD_ALWAYS_SHOW === "Y") {
        return true; // LLC only
      } else {
        if (
          checkAdditionalRoleAccessForUser(
            constants.DMP_DASHBOARD_COMMON_ACCESS_GROUP,
            user
          )
        ) {
          return true;
        }
        if (
          process.env.REACT_APP_DMP_DASHBOARD_COMMON_LOCATIONS !== undefined &&
          process.env.REACT_APP_DMP_DASHBOARD_COMMON_LOCATIONS.split(
            ","
          ).includes(currentLocation.locationNumber.toString())
        ) {
          return true;
        }
      }
    }
  }

  function shouldDisplayDashboardSth() {
    if (permit("dashboardSthAccess", user)) {
      if (process.env.REACT_APP_STH_DASHBOARD_ALWAYS_SHOW === "Y") {
        return true;
      } else {
        if (
          checkAdditionalRoleAccessForUser(
            constants.DMP_DASHBOARD_STH_ACCESS_GROUP,
            user
          )
        ) {
          return true;
        }
        if (
          process.env.REACT_APP_DMP_DASHBOARD_STH_LOCATIONS.split(",").includes(
            currentLocation.locationNumber.toString()
          )
        ) {
          return true;
        }
      }
    }
  }

  function shouldDisplayDashboardRP() {
    if (permit("routePlanner", user)) {
      if (process.env.REACT_APP_RP_VIEW_ALWAYS_SHOW === "Y") {
        return true; // LLC only
      } else if (
        checkAdditionalRoleAccessForUser(
          constants.DMP_DASHBOARD_COMMON_ACCESS_GROUP,
          user
        ) ||
        (process.env.REACT_APP_DMP_VIEW_RP_LOCATIONS !== undefined &&
          process.env.REACT_APP_DMP_VIEW_RP_LOCATIONS.split(",").includes(
            currentLocation.locationNumber.toString()
          ))
      ) {
        return true;
      }
    }
  }

  function shouldAllowWorkbin() {
    return (
      permit("workbinAccess", user) &&
      checkAdditionalRoleAccessForUser(constants.DMP_WORKBIN_ACCESS_GROUP, user)
    );
  }

  function handleLogoClick() {
    logNewRelicMetricsEvent(HEADER_NAV_BUTTON_EVENT, {
      location: "/",
      text: "Logo",
    });
  }

  return (
    <div className="header-container">
      <div className="utility-bar">
        <div className="utility-left">
          <div className="user">
            <div>
              Welcome <span id="user-name">{user.firstName}</span>
            </div>
          </div>
          <div className="userWM">
            <span id="user-ldap">{user.ldapId}</span>
          </div>
          <div className="location">
            {permit("locationDropdown", user) ? (
              <>
                <Location locations={user.locations} />
              </>
            ) : (
              <span id="location" />
            )}
          </div>
          <div className="location">
            {user.isGEUser && user.roles && <RolesDropdown />}
          </div>
        </div>
        <div className="logout">
          <div id="logout-link" onClick={logout}>
            Logout
          </div>
        </div>
      </div>
      <nav className="menu-bar">
        <div className="app-info">
          <div className="logo">
            <a href="/" onClick={handleLogoClick}>
              <HomeDepotIcon className="home-depot-logo" />
            </a>
          </div>
          <div className="app-name">
            <h1>Delivery Management Portal</h1>
          </div>
        </div>
        <div className="menu-links">
          <Menu
            mode={md ? "horizontal" : "inline"}
            id="menu-horizontal"
            onClick={({ key }) => routeChange(key)}
            selectedKeys={[activeItem]}
          >
            {shouldDisplayDashboardSth() && (
              <Menu.Item
                key="/dashboard-sth"
                icon={
                  <SthIcon
                    className={
                      activeItem === "/dashboard-sth"
                        ? "header-icons white-icon"
                        : "header-icons gray-icon"
                    }
                  />
                }
              >
                {
                  <p id="dashboard-beta-text">
                    STH
                  </p> /** <p> tag with id to correct line-height because of icon size ratio*/
                }
              </Menu.Item>
            )}
            {shouldDisplayDashboardCommon() && (
              <Menu.Item
                key="/dashboard-common"
                icon={
                  <LBDFDCIcon
                    className={
                      activeItem === "/dashboard-common"
                        ? "header-icons white-icon"
                        : "header-icons gray-icon"
                    }
                  />
                }
              >
                {
                  <p id="dashboard-beta-text">
                    LBD/FDC
                  </p> /** <p> tag with id to correct line-height because of icon size ratio*/
                }
              </Menu.Item>
            )}
            {permit("dashboard", user) && (
              <Menu.Item
                key="/"
                icon={
                  <ApplianceIcon
                    className={
                      activeItem === "/"
                        ? "header-icons white-icon"
                        : "header-icons gray-icon"
                    }
                  />
                }
              >
                Appliance
              </Menu.Item>
            )}
            {shouldDisplayDashboardRP() && (
              <Menu.Item
                key="/route-planner/routes"
                icon={
                  <RoutePlannerViewIcon
                    className={
                      activeItem === "/route-planner/routes"
                        ? "header-icons white-icon"
                        : "header-icons gray-icon"
                    }
                  />
                }
              >
                {
                  <p id="dashboard-beta-text">
                    Routing
                  </p> /** <p> tag with id to correct line-height because of icon size ratio*/
                }
              </Menu.Item>
            )}
            {permit("dataMaintenanceAccess", user) &&
              checkVendorEnabled("dataMaintenanceAccess", user) && (
                <Menu.Item
                  key="/dca"
                  icon={
                    <DataMaintenanceIcon
                      className={
                        activeItem === "/dca"
                          ? "header-icons white-icon"
                          : "header-icons gray-icon"
                      }
                    />
                  }
                >
                  Data Maintenance
                </Menu.Item>
              )}
            {shouldAllowWorkbin() && (
              <Menu.Item
                key="/workbin"
                icon={
                  <WorkBinIcon
                    className={
                      activeItem === "/workbin"
                        ? "header-icons white-icon"
                        : "header-icons gray-icon"
                    }
                  />
                }
              >
                Work Bin
              </Menu.Item>
            )}
            {permit("mailSandboxAccess", user) && user.userMailAccess && (
              <Menu.Item
                key="/mail"
                icon={
                  <MailIcon
                    className={
                      activeItem === "/mail"
                        ? "header-icons white-icon"
                        : "header-icons gray-icon"
                    }
                  />
                }
              >
                Mail
              </Menu.Item>
            )}
            {permit("MARSaccess", user) && (
              <Menu.Item
                key="mars"
                icon={
                  <MarsIcon
                    className={
                      activeItem === "mars"
                        ? "header-icons white-icon"
                        : "header-icons gray-icon"
                    }
                  />
                }
              >
                MARS
              </Menu.Item>
            )}
            {permit("EMTaccess", user) && (
              <Menu.Item
                key="emt"
                icon={
                  <EMTIcon
                    className={
                      activeItem === "emt"
                        ? "header-icons white-icon"
                        : "header-icons gray-icon"
                    }
                  />
                }
              >
                EMT
              </Menu.Item>
            )}
          </Menu>
          <Button className="barsMenu" type="primary" onClick={showDrawer}>
            <span className="barsBtn" />
          </Button>
          <Drawer
            className="menu-drawer"
            title="DMP"
            placement="right"
            onClose={onClose}
            open={visible}
          >
            <Menu
              onClick={({ key }) => routeChange(key)}
              mode={md ? "horizontal" : "inline"}
              selectedKeys={[activeItem]}
            >
              {shouldDisplayDashboardRP() && (
                <Menu.Item
                  key="/route-planner/routes/planning"
                  icon={
                    <RoutePlannerViewIcon
                      className={
                        activeItem === "/route-planner/routes/planning"
                          ? "header-icons white-icon"
                          : "header-icons gray-icon"
                      }
                    />
                  }
                >
                  {
                    <p id="dashboard-beta-text">
                      Routing
                    </p> /** <p> tag with id to correct line-height because of icon size ratio*/
                  }
                </Menu.Item>
              )}
              {shouldDisplayDashboardSth() && (
                <Menu.Item
                  key="/dashboard-sth"
                  icon={
                    <SthIcon
                      className={
                        activeItem === "/dashboard-sth"
                          ? "header-icons white-icon"
                          : "header-icons gray-icon"
                      }
                    />
                  }
                >
                  Dashboard STH
                </Menu.Item>
              )}
              {shouldDisplayDashboardCommon() && (
                <Menu.Item
                  key="/dashboard-common"
                  icon={
                    <LBDFDCIcon
                      className={
                        activeItem === "/dashboard-common"
                          ? "header-icons white-icon"
                          : "header-icons gray-icon"
                      }
                    />
                  }
                >
                  LBD/FDC
                </Menu.Item>
              )}
              {permit("dashboard", user) && (
                <Menu.Item
                  key="/"
                  icon={
                    <ApplianceIcon
                      className={
                        activeItem === "/"
                          ? "header-icons white-icon"
                          : "header-icons gray-icon"
                      }
                    />
                  }
                >
                  Appliance
                </Menu.Item>
              )}
              {permit("dataMaintenanceAccess", user) &&
                checkVendorEnabled("dataMaintenanceAccess", user) && (
                  <Menu.Item
                    key="/dca"
                    icon={
                      <DataMaintenanceIcon
                        className={
                          activeItem === "/dca"
                            ? "header-icons white-icon"
                            : "header-icons gray-icon"
                        }
                      />
                    }
                  >
                    Data Maintenance
                  </Menu.Item>
                )}
              {permit("workbinAccess", user) &&
                checkAdditionalRoleAccessForUser(workBin, user) && (
                  <Menu.Item
                    key="/workbin"
                    icon={
                      <WorkBinIcon
                        className={
                          activeItem === "/workbin"
                            ? "header-icons white-icon"
                            : "header-icons gray-icon"
                        }
                      />
                    }
                  >
                    Work Bin
                  </Menu.Item>
                )}
              {permit("mailSandboxAccess", user) && user.userMailAccess && (
                <Menu.Item
                  key="/mail"
                  icon={
                    <MailIcon
                      className={
                        activeItem === "/mail"
                          ? "header-icons white-icon"
                          : "header-icons gray-icon"
                      }
                    />
                  }
                >
                  Mail
                </Menu.Item>
              )}
              {permit("MARSaccess", user) && (
                <Menu.Item
                  key="mars"
                  icon={
                    <MarsIcon
                      className={
                        activeItem === "mars"
                          ? "header-icons white-icon"
                          : "header-icons gray-icon"
                      }
                    />
                  }
                >
                  MARS
                </Menu.Item>
              )}
              {permit("EMTaccess", user) && (
                <Menu.Item
                  key="emt"
                  icon={
                    <EMTIcon
                      className={
                        activeItem === "emt"
                          ? "header-icons white-icon"
                          : "header-icons gray-icon"
                      }
                    />
                  }
                >
                  EMT
                </Menu.Item>
              )}
            </Menu>
          </Drawer>
        </div>
      </nav>
    </div>
  );
};

Header.propTypes = { user: PropTypes.object.isRequired };

export default Header;
