import React, { useContext } from "react";
import "./Actions.less";
import PropTypes from "prop-types";
import { UserContext } from "../../context/RootContext";
import Pod from "./ProofOfDelivery/Pod";
import Reschedule from "./Reschedule/Reschedule";
import CancelLegacyRevisit from "./Cancel/CancelLegacyRevisit";
import ManualOrderButton from "./ManualOrder/ManualOrderButton";
import PrintOrder from "./PrintOrder/PrintOrder";
import permit, {
  checkAdditionalRoleAccessForUser,
} from "../../util/Permissions";
import { CancelDelivery } from "./CancelDelivery/CancelDelivery";
import * as constants from "../../util/Constants";
import { CancelComRevisit } from "../DeliveriesCommon/OrderDetails/Revisit/CancelComRevisit";
import RTVButton from "./ReturnToVendor/RTVButton";

const Actions = (props) => {
  const {
    latestEvent,
    actionTask,
    originalMSN,
    referenceId,
    customerDetail,
    workOrderDetail,
    purchaseOrderDetailList,
    location,
    orderSource,
    manualOrderable,
    notes,
    orderIndex,
    orderNumber,
    isLegacyRevisit,
    cancelledLines,
    isComRevisit,
    revisitLevelOfService,
  } = props;

  const { user } = useContext(UserContext);

  const isRevisit = isComRevisit || isLegacyRevisit;

  return (
    <div className="action-container">
      <div className="action">
        {permit("canCreateManualOrder", user) && manualOrderable && (
          <ManualOrderButton
            isComRevisit={isComRevisit}
            revisitLevelOfService={revisitLevelOfService}
            orderIndex={orderIndex}
            orderNumber={orderNumber}
            location={location}
          />
        )}
        <br />
      </div>
      <div className="action">
        {actionTask && actionTask.proofOfDelivery && (
          <div>
            <Pod
              referenceId={referenceId}
              customerDetail={customerDetail}
              sourceSystem={orderSource}
              orderNumber={orderNumber}
              orderIndex={orderIndex}
            />
            <br />
          </div>
        )}
      </div>
      <div className="action">
        {actionTask && actionTask.reschedule && (
          <div>
            <Reschedule
              className="rescheduleButton"
              workOrderDetail={workOrderDetail}
              originalMSN={originalMSN}
              purchaseOrderDetailList={purchaseOrderDetailList}
              customerDetail={customerDetail}
              orderNumber={orderNumber}
              location={location}
              orderSource={orderSource}
              orderIndex={orderIndex}
            />
            <br />
          </div>
        )}
      </div>
      <div className="action">
        {permit("cancelMO", user) && actionTask && actionTask.cancel && (
          <div>
            <CancelLegacyRevisit
              referenceId={referenceId}
              orderNumber={orderNumber}
              orderIndex={orderIndex}
              overlayType={"legacy"}
            />
            <br />
          </div>
        )}
      </div>
      <div className="action">
        {latestEvent.orderEventDescription !== constants.CANCELLED &&
          permit("cancelDelivery", user) &&
          !isRevisit &&
          checkAdditionalRoleAccessForUser(
            constants.DMP_CANCEL_DELIVERY_ACCESS_GROUP,
            user
          ) && (
            <div>
              <CancelDelivery
                referenceId={referenceId}
                orderNumber={orderNumber}
                orderIndex={orderIndex}
              />
              <br />
            </div>
          )}
      </div>
      <div className="action">
        {latestEvent.orderEventDescription !== constants.CANCELLED &&
          permit("cancelMO", user) &&
          isComRevisit && (
            <div>
              <CancelComRevisit
                workOrderNumber={workOrderDetail ? workOrderDetail.woNbr : ""}
                customerOrderNumber={
                  customerDetail ? customerDetail.custOrdId : ""
                }
                deliveryLocation={location}
                cancelledLines={cancelledLines}
                overlayType={"commons"}
              />
              <br />
            </div>
          )}
      </div>
      <div className="action">
        {permit("rtv", user) && (
          <div>
            <RTVButton
              orderIndex={orderIndex}
              orderNumber={orderNumber}
              poDetailList={purchaseOrderDetailList}
              orderStatus={latestEvent}
            />
            <br />
          </div>
        )}
      </div>
      <div className="action">
        <PrintOrder
          customerDetail={customerDetail}
          workOrderDetail={workOrderDetail}
          purchaseOrderDetailList={purchaseOrderDetailList}
          notes={notes}
          orderIndex={orderIndex}
          orderNumber={orderNumber}
        />
      </div>
    </div>
  );
};

Actions.propTypes = {
  latestEvent: PropTypes.object.isRequired,
  actionTask: PropTypes.object.isRequired,
  originalMSN: PropTypes.string,
  referenceId: PropTypes.string.isRequired,
  customerDetail: PropTypes.object.isRequired,
  workOrderDetail: PropTypes.object,
  purchaseOrderDetailList: PropTypes.array,
  location: PropTypes.string,
  orderSource: PropTypes.string,
  manualOrderable: PropTypes.bool,
  notes: PropTypes.string,
  isComRevisit: PropTypes.bool,
  orderIndex: PropTypes.number,
  orderNumber: PropTypes.string,
  isLegacyRevisit: PropTypes.bool,
  cancelledLines: PropTypes.array,
  revisitLevelOfService: PropTypes.string,
};

export default Actions;
