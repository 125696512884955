import React from "react";
import { Button } from "antd";
import useNewRelicMetrics from "../../../hooks/newRelicMetricsHook";
import useNotification from "../../../hooks/useNotification";
import { RTV } from "../../../util/NewRelicConstants";
import axios from "axios";

// TODO: [EMT-XXX]
// - Determine the optimal way to check if appliance is checked in at the warehouse
//   - Consider performance implications of checking status for multiple orders
//   - Discuss with team whether this should be included in the initial order data
//   - Evaluate if we need a separate API endpoint or if this should be part of order status
// - Define the exact data structure needed for RTV submission with the team
//   - What PO details are required
//   - How to handle multiple items in a single submission
//   - Required validation rules
// - Determine order status requirements for RTV
//   - What statuses allow RTV
//   - How to handle different order types

const RTVButton = ({ orderIndex, orderNumber, poDetailList, orderStatus }) => {
  const { logNewRelicMetricsEvent } = useNewRelicMetrics();
  const { openErrorNotification, openSuccessNotification, contextHolder } =
    useNotification();

  // TODO: [EMT-XXX] Replace environment variable with config server feature flag
  // This will be updated when the config server implementation is ready
  const isFeatureEnabled = process.env.REACT_APP_ENABLE_RTV === "Y";

  if (!isFeatureEnabled) {
    return null;
  }

  const handleRTVSubmission = async () => {
    try {
      logNewRelicMetricsEvent(RTV.BUTTON_CLICK);

      // TODO: [EMT-XXX] Replace with actual API data structure once defined with team
      const rtvData = {
        orderIndex,
        orderNumber,
        poDetails: poDetailList,
      };

      const response = await axios.post("/api/rtv-submission", rtvData);

      openSuccessNotification({
        message: "RTV Request Successful",
        description:
          "The return to vendor request has been processed successfully.",
      });

      logNewRelicMetricsEvent(RTV.SUBMIT_SUCCESS);
    } catch (error) {
      logNewRelicMetricsEvent(RTV.SUBMIT_ERROR);

      openErrorNotification({
        message: "RTV Request Failed",
        description: "Failed to process return to vendor request.",
        error,
      });

      console.error("RTV submission error:", error);
    }
  };

  return (
    <div id="rtv-button-container">
      {contextHolder}
      <Button className="default-btn" block onClick={handleRTVSubmission}>
        Return to Vendor
      </Button>
    </div>
  );
};

export default RTVButton;
