import React, { useContext, useEffect, useState } from "react";
import "./RoutePlannerTable.less";
import RoutePlannerTableContent from "./RoutePlannerTableContent";
import DatePickerComponent from "./common/DatePickerComponent";
import SearchBarComponent from "./common/SearchBarComponent";
import useRoutePlannerData from "../../hooks/RoutePlanner/useRoutePlannerData";
import { Spin } from "antd";
import ManifestButton from "./common/ManifestButton";
import RoutePlannerViewContext from "../../context/RoutePlannerViewContext";
import useNotification from "../../hooks/useNotification";

export default function RoutePlannerTable({
  heading,
  subheading,
  allowDatePicker,
  allowSearchBar,
  allowDownloadManifest,
}) {
  const { tab, date } = useContext(RoutePlannerViewContext);
  const { data, loading, error, enableDownloadManifest } =
    useRoutePlannerData();

  const { openErrorNotification, contextHolder } = useNotification();
  const [selectedRoutes, setSelectedRoutes] = useState([]);
  useEffect(() => {
    if (error) {
      openErrorNotification({
        message: "Error Retrieving Data",
        description:
          "We're Sorry! There is no data available at this time. Please try again later.",
      });
    }
  }, [error]);
  return (
    <div className="routeplanner-view-table">
      <div className="routeplanner-view-table-header">
        <h3>{heading}</h3>
        <h4>{subheading}</h4>
        {allowDownloadManifest && (
          <div
            className="routeplanner-view-download-manifest-button"
            data-testid="rp-manifest-button"
          >
            <ManifestButton
              selectedDate={date}
              isEnabled={enableDownloadManifest}
              tab={tab}
              selectedRoutes={selectedRoutes}
            />
          </div>
        )}
      </div>
      <div className="routeplanner-view-table-query-section">
        {allowSearchBar && <SearchBarComponent />}
        {allowDatePicker && <DatePickerComponent />}
      </div>

      <div className="routeplanner-view-table-content">
        {loading && (
          <div className="rp-spinner" data-testid="rp-spinner">
            <Spin size="large" />
          </div>
        )}
        {contextHolder}
        <RoutePlannerTableContent
          tab={tab}
          data={data}
          error={error}
          loading={loading}
          setSelectedRoutes={setSelectedRoutes}
        />
      </div>
    </div>
  );
}
