import React from "react";
import "./RouteInfo.less";

function RouteInfo({ routeDetail }) {
  const { truckId, routeID, driverName } = routeDetail;

  return (
    <div className="route-info-card">
      {truckId && (
        <div>
          <span className="route-info">Truck ID: </span>
          <span className="route-info-value">{truckId}</span>
        </div>
      )}
      {routeID && (
        <div>
          <span className="route-info">Route ID: </span>
          <span className="route-info-value">{routeID}</span>
        </div>
      )}
      {driverName && (
        <div>
          <span className="route-info">Driver: </span>
          <span className="route-info-value">{driverName}</span>
        </div>
      )}
    </div>
  );
}

export default RouteInfo;
