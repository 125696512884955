import React, { useContext, useState } from "react";
import { Button } from "antd";
import permit from "../../util/Permissions";
import { UserContext } from "../../context/RootContext";
import useNewRelicMetrics from "../../hooks/newRelicMetricsHook";
import { TRUE_OVERAGE } from "../../util/NewRelicConstants";
import "./TrueOverageButton.less";
import TrueOverageModal from "./TrueOverageModal";

// TODO: [EMT-XXX] Add E2E tests to cover:
// - Complete form submission flow
// - Vendor selection and validation
// - API integration verification
// - Success/error scenarios
const TrueOverageButton = () => {
  const { user } = useContext(UserContext);
  const { logNewRelicMetricsEvent } = useNewRelicMetrics();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // TODO: [EMT-XXX] Replace environment variable with config server feature flag
  // This will be updated when the config server implementation is ready
  const isFeatureEnabled = process.env.REACT_APP_ENABLE_TRUE_OVERAGE === "Y";

  if (!isFeatureEnabled) {
    return null;
  }

  return (
    <div className="true-overage-container">
      {permit("trueOverage", user) && (
        <>
          <Button
            type="primary"
            onClick={() => {
              logNewRelicMetricsEvent(TRUE_OVERAGE.BUTTON_CLICK);
              setIsModalOpen(true);
            }}
          >
            True Overage
          </Button>
          <TrueOverageModal
            open={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
          />
        </>
      )}
    </div>
  );
};

export default TrueOverageButton;
