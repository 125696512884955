import React from "react";
import { createBrowserRouter } from "react-router-dom";
import AccessRequest from "../components/AccessRequest/AccessRequest";
import Dashboard from "../components/Dashboard/Dashboard";
import { DashboardCommon } from "../components/DashboardCommon/DashboardCommon";
import Mail from "../components/Mail/Mail";
import WorkBin from "../components/WorkBin/WorkBin";
import RoutePlannerView from "../components/RoutePlannerView/RoutePlannerView";
import PrintManifestView from "../components/RoutePlannerView/PrintManifestView";
import RouteDetailsView from "../components/RoutePlannerView/RouteDetailsView";
import RoutePlannerTable from "../components/RoutePlannerView/RoutePlannerTable";
import NotFound from "../components/ErrorComponents/NotFound";
import DashboardContext from "../context/DashboardContext";
import * as constants from "../util/Constants";
import permit, {
  checkAdditionalRoleAccessForUser,
  checkVendorEnabled,
} from "../util/Permissions";
import moment from "moment-timezone";
import ProtectedRoute from "./ProtectedRoute";
import AppLayout from "../components/Layouts/AppLayout";
import {
  dcaParams,
  shouldAllowDashboardCommon,
  shouldAllowDashboardSth,
  shouldAllowRoutePlannerView,
  shouldAllowWorkbin,
} from "./authHelpers";
import { RoutePlannerViewContextProvider } from "../context/RoutePlannerViewContext";

const getRoutes = (user, currentLocation) =>
  createBrowserRouter([
    {
      path: "/",
      element: <AppLayout user={user} />,
      // errorElement: <GlobalErrorBoundary />, //TODO:: consider adding GlobalErrorBoundry
      children: [
        {
          path: "/access-request",
          element: <AccessRequest />,
        },
        {
          path: "/",
          element: (
            <ProtectedRoute
              isAllowed={permit("dashboard", user)}
              element={
                <DashboardContext.Provider value={constants.DASHBOARD_CONTEXT}>
                  <Dashboard />
                </DashboardContext.Provider>
              }
              redirectPath={
                checkAdditionalRoleAccessForUser(
                  constants.DMP_WORKBIN_ACCESS_GROUP,
                  user
                )
                  ? "/workbin"
                  : "/access-request"
              }
            />
          ),
        },
        {
          path: "dashboard-common",
          element: (
            <ProtectedRoute
              isAllowed={shouldAllowDashboardCommon(user, currentLocation)}
              element={
                <DashboardContext.Provider
                  value={constants.DASHBOARD_COMMON_CONTEXT}
                >
                  <DashboardCommon />
                </DashboardContext.Provider>
              }
              redirectPath="/"
            />
          ),
        },
        {
          path: "dashboard-sth",
          element: (
            <ProtectedRoute
              isAllowed={shouldAllowDashboardSth(user, currentLocation)}
              element={
                <DashboardContext.Provider
                  value={constants.DASHBOARD_STH_CONTEXT}
                >
                  <DashboardCommon />
                </DashboardContext.Provider>
              }
              redirectPath="/"
            />
          ),
        },
        {
          path: "mail",
          element: (
            <ProtectedRoute
              isAllowed={
                user.userMailAccess && permit("mailSandboxAccess", user)
              }
              element={<Mail />}
              redirectPath="/"
            />
          ),
        },
        {
          path: "dca",
          element: (
            <ProtectedRoute
              isAllowed={
                permit("dataMaintenanceAccess", user) &&
                checkVendorEnabled("dadataMaintenanceAccess", user)
              }
              element={
                <div style={{ paddingTop: "115px " }}>
                  <iframe
                    width="100%"
                    height="850"
                    src={`${process.env.REACT_APP_DCA_URL}/micro${dcaParams(
                      user,
                      currentLocation
                    )}`}
                  />
                </div>
              }
              redirectPath="/"
            />
          ),
        },
        {
          path: "workbin",
          element: (
            <ProtectedRoute
              isAllowed={shouldAllowWorkbin(user)}
              element={<WorkBin />}
              redirectPath="/"
            />
          ),
        },
        {
          path: "route-planner/routes",
          element: (
            <ProtectedRoute
              isAllowed={shouldAllowRoutePlannerView(user, currentLocation)}
              element={
                <DashboardContext.Provider
                  value={constants.DASHBOARD_ROUTE_PLANNER_CONTEXT}
                >
                  <RoutePlannerViewContextProvider>
                    <RoutePlannerView />
                  </RoutePlannerViewContextProvider>
                </DashboardContext.Provider>
              }
              redirectPath="/"
            />
          ),
          children: [
            {
              path: "planning/details/:routeId",
              element: (
                <RouteDetailsView
                  heading="Route Details"
                  subheading="View all orders assigned to this route"
                />
              ),
            },
            {
              path: "planning/details/:routeId/manifest",
              element: <PrintManifestView disableCsvButton={true} />,
            },
            {
              path: "planning/manifest",
              element: <PrintManifestView />,
            },
            {
              path: "dispatch/details/:routeId",
              element: (
                <RouteDetailsView
                  heading="Route Details"
                  subheading={`View all orders assigned to this route for today ${moment()
                    .locale("en")
                    .format("MMMM DD, YYYY")}`}
                />
              ),
            },
            {
              path: "dispatch/details/:routeId/manifest",
              element: <PrintManifestView disableCsvButton={true} />,
            },
            {
              path: "dispatch/manifest",
              element: <PrintManifestView />,
            },
            {
              path: "planning",
              element: (
                <RoutePlannerTable
                  heading="Route Data"
                  subheading="View all routes and download manifests for selected date"
                  allowDatePicker
                  allowDownloadManifest
                />
              ),
            },
            {
              path: "dispatch",
              element: (
                <RoutePlannerTable
                  heading="Route Data"
                  subheading={`View all routes for today ${moment()
                    .locale("en")
                    .format("MMMM DD, YYYY")}`}
                  allowDownloadManifest
                />
              ),
            },
            {
              path: "unassigned",
              element: (
                <RoutePlannerTable
                  heading="Unassigned Orders"
                  subheading="View all orders that have not been assigned to any route"
                  allowDatePicker
                />
              ),
            },
            {
              path: "archive",
              element: (
                <RoutePlannerTable
                  heading="Archive Data"
                  subheading="View past delivery details"
                  allowSearchBar
                />
              ),
            },
          ],
        },
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
  ]);

export default getRoutes;
